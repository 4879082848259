import React, { Fragment, useEffect, useState } from "react";
import Aside from "./Aside";
import Header from "./Header";
import { Link, useHistory, withRouter } from "react-router-dom";
import axios from "axios";
import { appConfig } from "../config/App";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import { getRoleAndUserId } from "../utils/getRoleAndUserId";

const Report = (props) => {
  const [data, setData] = useState([]);
  const role = getRoleAndUserId().role;
  const history = useHistory();

  let isHidden = true;
  switch (role) {
    case "cs":
      isHidden = false;
  }

  function onLogout() {
    localStorage.clear();
    history.push("/login");
  }

  function detailTicket(data) {
    props.history.push({
      pathname: "/detailticket/" + data.noTicket,
      state: {
        data: data,
      },
    });
  }

  async function fetchTicketData() {
    const authToken = localStorage.getItem("accessToken");
    let roleAndUserId = getRoleAndUserId();
    try {
      let url;
      switch (roleAndUserId.role) {
        case "delivery":
          url = appConfig.apiUrl + "?deliveryUserId=" + roleAndUserId.userId;
          break;
        case "service":
          url = appConfig.apiUrl + "?serviceUserId=" + roleAndUserId.userId;
          break;
        default:
          url = appConfig.apiUrl + "?userId=" + roleAndUserId.userId;
          break;
      }
      const fetchDataByQuery = await axios.get(url, {       
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setData(fetchDataByQuery.data.data.rows);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message + "! Please Create Ticket First!",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Internal Server Error!",
        });
      }
    }
  }

  useEffect(() => {
    fetchTicketData();
  }, []);

  const columns = [
    {
      name: "No Ticket",
      selector: (data) => data.noTicket,
      sortable: true,
      wrap: true,
    },
    {
      name: "Customer",
      selector: (data) => data.customer,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      name: "Status",
      selector: (data) => data.status,
      sortable: true,
      wrap: true,
      width: "150px",
      conditionalCellStyles: [
        {
          when: (data) => data.status === "open",
          style: {
            backgroundColor: "#0dcaf0",
          },
        },
        {
          when: (data) => data.status === "pending",
          style: {
            backgroundColor: "#0d6efd",
            color: "white",
          },
        },
        {
          when: (data) => data.status === "on-going",
          style: {
            backgroundColor: "#ffc107",
            width: "50%",
          },
        },
        {
          when: (data) => data.status === "success",
          style: {
            backgroundColor: "#20c997",
          },
        },
      ],
    },
    {
      name: "Customer User",
      selector: (data) => data.user? data.user.personalInfo.name : "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Service User",
      selector: (data) =>
        data.service.user ? data.service.user.personalInfo.name : "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Delivery User",
      selector: (data) =>
        data.delivery.user ? data.delivery.user.personalInfo.name : "-",
      sortable: true,
      wrap: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "30px", // override the cell padding for head cells
        paddingRight: "8px",
        width: "200px",
        fontSize: "16px",
        fontWeight: 50,
        alignItems: "center",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "14px",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  };

  return (
    <>
      <Aside logout={onLogout} />
      <Header />
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Report</h1>
                </div>
              </div>
            </div>
          </div>

          {/* Main content */}
          <section className="content">
            {/* /.Chart */}
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">
                  <i className="fas fa-chart-pie mr-1" />
                  Report Table
                </h3>
                <div className="card-tools"></div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="tab-content p-0">
                  {/* Morris chart - Sales */}
                  <div className="card">
                    <div className="card-header border-transparent">
                      <h3 className="card-title">Latest Ticket</h3>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus" />
                        </button>
                      </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={data}
                          customStyles={customStyles}
                          highlightOnHover
                          striped
                          responsive
                          pagination
                        />
                      </div>
                    </div>
                    <div className="card-footer clearfix">
                      <Link to={"/form"} hidden={isHidden}>
                        <a className="btn btn-sm btn-info float-left">
                          Create New Ticket
                        </a>
                      </Link>
                      <Link to={"/table"}>
                        <a className="btn btn-sm btn-secondary float-right">
                          View All Orders
                        </a>
                      </Link>
                    </div>
                    {/* /.card-footer */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default withRouter(Report);