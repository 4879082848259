import React from "react";

function FiturWhatsapp() {
  return (
    <div className="landingpage__fitur-chat box">
      <a
        href="https://api.whatsapp.com/send/?phone=6281387083457&text=Pelanggan+yang+terhormat+,+mohon+lengkapi+data+dibawah+ini%0aNama+:%0aPerusahaan+:%0aPesan+:&type=phone_number&app_absent=0"
        alt="Whatsapp"
        title="Whatsapp"
        className="whatsApp-logo"
        style={{ color: "green" }}
      >
        <div class="box">
          <p className="whatsapp-text mt-3">Whatsapp</p>
          <i class="fab fa-whatsapp fa-2x icon-wa whatsapp"></i>
          {/* <div class="icon whatsapp"></div> */}
        </div>
      </a>
    </div>
  );
}

export default FiturWhatsapp;
