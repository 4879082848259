import React from "react";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { getTicketNoToken } from "../../utils/api";

class InputTicket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: "",
    };

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onKeywordChangeHandler = this.onKeywordChangeHandler.bind(this);
  }

  async onSubmitHandler(event) {
    event.preventDefault();
    const ticket = await getTicketNoToken(this.state.keyword);
    if (ticket.error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No Ticket Not Found!",
        // footer: '<a href="">Why do I have this issue?</a>'
      });
    } else {
      this.props.history.push({
        pathname: `/ticket-item-page`,
        state: {
          data: ticket.data,
        },
      });
    }
  }

  onKeywordChangeHandler(event) {
    const { value } = event.target;

    this.setState(() => {
      return {
        keyword: value,
      };
    });
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center container mb-3 pb-3">
        <div>
          <div>
            <form onSubmit={this.onSubmitHandler}>
              <div className="form-group">
                <input
                  className="form-control form-control-lg box-form"
                  type="text"
                  placeholder="Masukkan nomor tiket"
                  value={this.state.keyword}
                  onChange={this.onKeywordChangeHandler}
                  required
                />
              </div>
              <div className="div-button">
                <button type="submit" className="button-cek">
                  Cek Tiket
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(InputTicket);
