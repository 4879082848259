import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { appConfig } from '../config/App'
import Aside from './Aside'
import Header from './Header'
import {Link, useHistory} from 'react-router-dom'
import {getRoleAndUserId} from "../utils/getRoleAndUserId";
import DataTable from 'react-data-table-component';
import { CSVLink } from 'react-csv';
import Swal from 'sweetalert2';


const Data = () => {
    const history = useHistory();
    const role = getRoleAndUserId().role;
    let updatePath = 'form-cs';
    let isHidden = true;
    switch (role) {
        case 'cs':
            updatePath = "form-cs";
            isHidden = false;
            break;
        case 'service':
            updatePath = "form-service";
            break;
        case 'delivery':
            updatePath = "form-delivery";
            break;
    }

    const {noTicket} = useState([]);
    const [data, setData] = useState([]);
    const [service, setService] = useState([]);
    const [delivery, setDelivery] = useState([]);
     const [searchQuery, setSearchQuery] = useState('');
     const [searchQuery2, setSearchQuery2] = useState('');
     const [searchQuery3, setSearchQuery3] = useState('');

useEffect( () => {
        fetchTicketData();
    }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleSearch2Change = (event) => {
    setSearchQuery2(event.target.value);
  };
  const handleSearch3Change = (event) => {
    setSearchQuery3(event.target.value);
  };

  function onLogout() {
    localStorage.clear();
    history.push("/login");
  }

    const handleDelete = async (sendData) => {
      const confirmed = await Swal.fire({
        title: 'Apakah kamu yakin?',
        text: 'Datamu Akan Hilang!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Iya, Hapus!',
        cancelButtonText: 'Tidak, Jangan',
        reverseButtons: true
      });
      
      if (confirmed.isConfirmed) {
        try {
          const authToken = localStorage.getItem("accessToken");
          const res = await axios.delete(`${appConfig.baseApiUrl}/ticket?noTicket=${sendData}`, {
            headers: { "Authorization": `Bearer ${authToken}` }
          });
          Swal.fire({
            title: 'Terhapus!',
            text: 'Datamu Telah Terhapus.',
            icon: 'success'
          });
          window.location.reload();
        } catch (error) {
          Swal.fire({
            title: 'Gagal!',
            text: 'Gagal Saat Hapus Data.',
            icon: 'error'
          });
        }
      }
    };
    
    async function fetchTicketData() {
      try {
        const authToken = localStorage.getItem("accessToken");
        const res = await axios.get(appConfig.apiUrl, { headers: { Authorization: `Bearer ${authToken}` } });
        setData(res.data.data);
        setService(res.data.data.service);
        setDelivery(res.data.data.delivery);
        return res.data.data;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Handle unauthorized error
          console.error("Unauthorized", error);
        } else {
          // Handle other errors
          console.error(error);
        }
      }
    }
    
    const filteredData = data.filter((data) =>
  Object.values(data)
      .join('')
      .toLowerCase()
      .includes(searchQuery.toLowerCase()) 
      &&
      Object.values(data.service?data.service: '-')
      .join('')
      .toLowerCase()
      .includes(searchQuery2.toLowerCase())
      &&
      Object.values(data.delivery?data.delivery: '-')
      .join('')
      .toLowerCase()
      .includes(searchQuery3.toLowerCase())
  );

  const headers = [
    { label: 'No. Ticket', key: 'noTicket' },
    { label: 'Customer', key: 'customer' },
    { label: 'Detail Customer', key: 'detailCustomer' },
    { label: 'Produk', key: 'type' },
    { label: 'Serial Number', key: 'serialNumber' },
    { label: 'Samperso', key: 'samperso' },
    { label: 'Status', key: 'status' },
    { label: 'Masa Garansi', key: 'isGuarantee', formatter: (data) => (data ? (data.isGuarantee ? 'Masih Garansi' : 'Sudah Exp') : '-') },
  { label: 'Status Pembayaran', key: 'payStatus', formatter: (data) => (data ? (data.payStatus ? 'Sudah' : 'Belum') : '-') },
    { label: 'Tanggal Pembayaran', key: 'payDate' },
    { label: 'Line', key: 'line' },
    { label: 'Masalah', key: 'case' },
    { label: 'Catatan', key: 'remark' },
    { label: 'Resi Accepted', key: 'resiAccepted' },
    { label: 'Expedisi', key: 'expedisi' },
    { label: 'No Resi', key: 'noResi' },
    { label: 'Aktifitas', key: 'service.activity' },
    { label: 'Service Selesai', key: 'service.serviceDone' },
    { label: 'Hasil Cek', key: 'service.hasilCek' },
    { label: 'No Po', key: 'service.noPo' },
    { label: 'Update Status', key: 'status' },
    { label: 'New Sn', key: 'service.newSn' },
    { label: 'New Sam', key: 'service.newSam' },
    { label: 'Alat Masuk', key: 'delivery.deviceIn' },
    { label: 'Alat Keluar', key: 'delivery.deviceOut' },
    { label: 'Expedisi', key: 'delivery.expedisi' },
    { label: 'Resi', key: 'delivery.resi' },
    { label: 'Catatan', key: 'delivery.remark' },
  ];
  
    const columns = [
        {
           name: 'No Ticket',
           id: 'noTiket',
           selector: (data) => data.noTicket,
           sortable: true,
           wrap: true,
           
        },
        {
           name: 'Customer',
           id: 'cus',
           selector: (data) => (data? data.customer: '-'),
           sortable: true,
           wrap: true,
        },
        {
           name: 'Detail Customer',
           id: 'detil',
           selector: (data) => (data? data.detailCustomer: '-'),
           sortable: true,
           wrap: true,
          
        },
        {
           name: 'Produk',
           id: 'prod',
           selector: (data) => (data? data.type: '-'),
           sortable: true,
           wrap: true,
           
        },
        {
            name: 'Serial Number',
            id: 'seri',
            selector: (data) => (data? data.serialNumber: '-'),
            sortable: true,
            wrap: true,
           
         },
          {
           name: 'Samperso',
           id: 'samper',
           selector: (data) => (data? data.samperso: '-'),
           sortable: true,
           
        },
        {
            name: 'Status',
            id: 'status',
            selector: (data) => (data?data.status: '-'),
            sortable: true,
            width: '120px',
            conditionalCellStyles: [
              {
                when: (data) => data.status === 'open',
                style: {
                  backgroundColor: "#0dcaf0",
                  color: 'white',
                },
              },
              {
                when: (data) => data.status === 'pending',
                style: {
                  backgroundColor: "#0d6efd",
                  color: 'white',
                },
              },
              {
                when: (data) => data.status === 'on-going',
                style: {
                  backgroundColor: "#ffc107",
                  width: '50%',
                  color: 'white',
                },
              },
              {
                when: (data) => data.status === 'success',
                style: {
                  backgroundColor: '#20c997',
                  color: 'white',
                },
              },
            ],
         },
         {
            name: 'Masa Garansi',
            id: 'garan',
            selector: (data) => (data? data.isGuarantee?'Masih Garansi':'Sudah Exp': '-'),
            sortable: true,
           
         },
         {
            name: 'Status Pembayaran',
             id: 'statpem',
            selector: (data) => (data? data.payStatus?'Sudah':'Belum': '-'),
            sortable: true,
            wrap: true,
           
         },
         {
            name: 'Tanggal Pembayaran',
             id: 'tangpem',
            selector: (data) => (data? data.payDate: '-'),
            sortable: true,
            wrap: true,
           
         },
         {
            name: 'Line',
             id: 'line',
            selector: (data) => (data? data.line: '-'),
            sortable: true,
         },
         {
            name: 'Masalah',
             id: 'masalah',
            selector: (data) => (data? data.case: '-'),
            sortable: true,
         },
         {
            name: 'Catatan',
             id: 'catat',
            selector: (data) => (data? data.remark: '-'),
            sortable: true,
            wrap: true,
            allowOverflow: true
         },
         {
            name: 'Resi Accepted',
             id: 'resiacc',
            selector: (data) =>( data? data.resiAccepted: '-'),
            sortable: true,
            wrap: true,
            
         },
         {
            name: 'Expedisi',
             id: 'exped',
            selector: (data) => (data? data.expedisi: '-'),
            sortable: true,
         },
         {
            name: 'No Resi',
             id: 'nores',
            selector: (data) => (data? data.noResi: "-"),
            sortable: true,
         },
        //  {
        //   name: "Customer User",
        //   selector: (data) => (data.user?data.user.personalInfo.name:'-'),
        //   sortable: true,
        //   wrap: true,
        // },
        // {
        //   name: "Service User",
        //   selector: (data) =>
        //     (data.service.user ? data.service.user.personalInfo.name : "-"),
        //   sortable: true,
        //   wrap: true,
        // },
        // {
        //   name: "Delivery User",
        //   selector: (data) =>
        //     (data.delivery.user ? data.delivery.user.personalInfo.name : "-"),
        //   sortable: true,
        //   wrap: true,
        // },
         {
            name: 'Aktifitas',
             id: 'aktifiti',
            selector: (data) => (data.service? data.service.activity: "-"),
            sortable: true,
            wrap: true,
         },
         {
            name: 'Service Selesai',
             id: 'service',
            selector: (data) => (data.service?data.service.serviceDone: "-"),
            sortable: true,
           
         },
         {
            name: 'Hasil Cek',
             id: 'hasilcek',
            selector: (data) => (data.service?data.service.hasilCek: "-"),
            sortable: true,
            wrap: true,
         },
         {
            name: 'No Po',
             id: 'nopo',
            selector: (data) => (data.service?data.service.noPo: "-"),
            sortable: true,
         },
         {
            name: 'Update Status',
             id: 'updatstat',
            selector: (data) => (data?data.status: '-'),
            sortable: true,
            width: '160px',
            conditionalCellStyles: [
              {
                when: (data) => data.status === 'open',
                style: {
                  backgroundColor: "#0dcaf0",
                  color: 'white',
                },
              },
              {
                when: (data) => data.status === 'pending',
                style: {
                  backgroundColor: "#0d6efd",
                  color: 'white',
                },
              },
              {
                when: (data) => data.status === 'on-going',
                style: {
                  backgroundColor: "#ffc107",
                  color: 'white',
                },
              },
              {
                when: (data) => data.status === 'success',
                style: {
                  backgroundColor: '#20c997',
                  color: 'white',
                },
              },
            ],
            
         },
         {
            name: 'New Sn',
             id: 'newsn',
            selector: (data) => (data.service?data.service.newSn: "-"),
            sortable: true,
         },
         {
            name: 'New Sam',
             id: 'newsam',
            selector: (data) =>( data.service?data.service.newSam: "-"),
            sortable: true,
         },
         {
            name: 'Alat Masuk',
             id: 'alatmas',
            selector: (data) => (data.delivery?data.delivery.deviceIn: "-"),
            sortable: true,
            wrap: true,
            
         },
         {
            name: 'Alat Keluar',
             id: 'alatKeluar',
            selector: (data) => (data.delivery?data.delivery.deviceOut: "-"),
            sortable: true,
            wrap: true,
            
         },
         {
            name: 'Expedisi',
             id: 'ekspedisi',
            selector: (data) => (data.delivery?data.delivery.expedisi: "-"),
            sortable: true,
         },
         {
            name: 'Resi',
             id: 'resii',
            selector: (data) => (data.delivery?data.delivery.resi: "-"),
            sortable: true,
         },
            {
                name: 'Catatan',
                 id: 'catatann',
                selector: (data) => (data.delivery?data.delivery.remark: "-"),
                sortable: true,
                wrap: true,
             },
             {
                name: 'Perbarui',
                id: 'perbar',
                button: true,
                cell: (data) =><Link to={`/${updatePath}/${data.noTicket}`}> <button class="btn btn-outline-info btn-sm">Perbarui</button></Link>,
                },
                {
                    name: 'Hapus',
                    id: 'hapus',
                    button: true,
                    cell: (data) => <button class="btn btn-outline-danger btn-sm" hidden={isHidden} onClick={() => handleDelete(data.noTicket)}>Hapus</button>,  
                },
        ];

const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height 
            },
        },
        headCells: {
            style: {
                paddingLeft: '30px', // override the cell padding for head cells
                paddingRight: '8px',
                width: '200px',
                fontSize: '16px',
                fontWeight: 50,
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                fontSize: '14px',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
    };

    return (
    <><Aside logout={onLogout}/><Header />{" "}<div className="content-wrapper">
          <section className="content-header">
              <div className="container-fluid">
                  <div className="row mb-2">
                      <div className="col-sm-6">
                          <h1>DataTable</h1>
                      </div>
                      <div className="col-sm-6">
                          <ol className="breadcrumb float-sm-right">
                              <li className="breadcrumb-item active">DataTables</li>
                          </ol>
                      </div>
                  </div>
              </div>
          </section>
          <section className="content">
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-12">
                          <div className="card">
                              <div className="card-header">
                                  <h3 className="card-title">Data Complain</h3>
                              </div>
                              <div>
                              <CSVLink 
                              data={filteredData} 
                              
                               headers={headers}
                              separator={";"} 
                              filename={'IdPayTiket'}
                              ><button className='btn btn-outline-success ml-3 mt-3'>Export to CSV</button></CSVLink>
                              </div>
                              <div className="card-body table-responsive">
                                  <input type="text" className='mb-3' placeholder='Costumer Service' value={searchQuery} onChange={handleSearchChange} />
                                  <input type="text" className='mb-3'  placeholder='Service' value={searchQuery2} onChange={handleSearch2Change} />
                                  <input type="text" className='mb-3'  placeholder='Delivery' value={searchQuery3} onChange={handleSearch3Change} />
                                  <DataTable 
                                  columns={columns} 
                                  data={filteredData} 
                                  customStyles={customStyles} 
                                  highlightOnHover 
                                  striped 
                                  responsive 
                                  pagination 
                                   />
                              </div>       
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </div></>
  );
}

export default Data