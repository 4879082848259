import React from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
// import { useHistory } from "react-router-dom"; 

const BodyContent = (props) => {
  let data = props.data;

  console.log(data)

  // const history = useHistory();

  // function detailTicket(id){
  //   history.push(`/ticket/${id}`);
  // };
  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "16px", // override the cell padding for head cells
        paddingRight: "16px",
        fontSize: "16px",
        fontWeight: 500,
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontWeight: 500,
        fontSize: "16px",
      },
    },
  };

  const columns = [
    {
      name: "Status Tiket",
      selector: (data) => data.status,
      sortable: true,
      wrap: true,
    },
    {
      name: "No Tiket",
      selector: (data) => <Link to={`/ticket/${data.noTicket}`}>{data.noTicket}</Link>,
      sortable: true,
      wrap: true,
    },
    {
      name: "Costumer",
      selector: (data) => data.customer,
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <section class="container  justify-content-center">
      <div className="mt-5 pt-5">
        <div className="jumbotron">
          <h3>Status Tiket</h3>
          <div className="card-body table-responsive">
            <DataTable
              columns={columns}
              data={data}
              customStyles={customStyles}
              highlightOnHover
              striped
              responsive
              pagination
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BodyContent;

// import React from "react";
// import { Link } from "react-router-dom";

// const BodyContent = (props) => {
//   let data = props.data;

//   return (
//     <section className="container">
//       <div className="body__content-container">
//         {data.map((rows) => {
//           return (
//             <div className="body__content">
//               <p className="body__content-status">
//                 <b>{rows.status}</b>
//               </p>
//               <p className="body__content-noticket">
//                 <Link to={`/ticket/${rows.noTicket}`}>{rows.noTicket}</Link>
//               </p>
//               <p className="body__content-customer">{rows.customer}</p>
//             </div>
//           );
//         })}
//       </div>
//     </section>
//   );
// };

// export default BodyContent;
