import React from 'react';

import InputCreateTicket from '../../component/Landingpages/InputCreateTicket';

function CreateTicketPage() {

    return (
        <section className='container'>
            <InputCreateTicket />
        </section>
    )
}

export default CreateTicketPage;
