import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Content from "../component/Content";
import Data from "../component/Data";
import Formulir from "../component/Formulir";
import Report from "../component/Report";
import TicketItem from "../component/Landingpages/TicketItem";
import ContentList from "../component/Home/ContentList";
import DetailPageWrapper from "../pages/Home/DetailPage";
import LoginAdminPage from "../pages/Login/LoginAdminPage";
import CreateTicketPage from "../pages/Landingpage/CreateTicketPage";
import TicketItemPage from "../pages/Landingpage/TicketItemPage";
import LandingPage from "../pages/Landingpage/LandingPage";
import jwtDecode from "jwt-decode";
import FormulirService from "../component/FormulirService";
import FormulirCs from "../component/FormulirCs";
import FormulirDelivery from "../component/FormulirDelivery";

class ListPages extends React.Component {
  routeGuard = (Component) => {
    if (localStorage.getItem("accessToken")) {
      let token = localStorage.getItem("accessToken");
      const { exp } = jwtDecode(token);
      const expirationTime = exp * 1000 - 60000;
      if (Date.now() >= expirationTime) {
        localStorage.clear();
        return <Redirect to="/login"></Redirect>;
      } else {
        return <Component />;
      }
    } else {
      return <Redirect to="/login"></Redirect>;
    }
  };

  routeLP = (Component) => {
    if (localStorage.getItem("accessToken")) {
      return <Redirect to="/home"></Redirect>;
    } else {
      return <Component />;
    }
  };

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return this.routeLP(LandingPage);
            }}
          ></Route>
          <Route
            path="/login"
            render={() => {
              return this.routeLP(LoginAdminPage);
            }}
          ></Route>
          <Route
            path="/create-ticket-visitor"
            render={() => {
              return this.routeLP(CreateTicketPage);
            }}
          ></Route>
          <Route
            path="/ticket-item-page"
            render={() => {
              return this.routeLP(TicketItemPage);
            }}
          ></Route>
          <Route
            path="/ticket-item"
            render={() => {
              return this.routeLP(TicketItem);
            }}
          ></Route>
          <Route
            path="/home"
            render={() => {
              return this.routeGuard(Content);
            }}
          ></Route>
          <Route
            path="/content-list/:status"
            render={() => {
              return this.routeGuard(ContentList);
            }}
          ></Route>
          <Route
            path="/ticket/:id"
            render={() => {
              return this.routeGuard(DetailPageWrapper);
            }}
          ></Route>
          <Route
            path="/form"
            render={() => {
              return this.routeGuard(Formulir);
            }}
          ></Route>
          <Route
            path="/form-cs/:noTicket"
            render={() => {
              return this.routeGuard(FormulirCs);
            }}
          ></Route>
          <Route
            path="/form-service/:noTicket"
            render={() => {
              return this.routeGuard(FormulirService);
            }}
          ></Route>
          <Route
            path="/form-delivery/:noTicket"
            render={() => {
              return this.routeGuard(FormulirDelivery);
            }}
          ></Route>
          <Route
            path="/table"
            render={() => {
              return this.routeGuard(Data);
            }}
          ></Route>
          <Route
            path="/report"
            render={() => {
              return this.routeGuard(Report);
            }}
          ></Route>
        </Switch>
      </React.Fragment>
    );
  }
}

export default ListPages;
