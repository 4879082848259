import React from "react";
import { Link } from "react-router-dom";
import { getRoleAndUserId } from "../utils/getRoleAndUserId";

function Aside({ logout }) {
  let isHidden = true;
  const role = getRoleAndUserId().role;
  switch (role) {
    case "cs":
      isHidden = false;
      break;
    case "service":
      break;
    case "delivery":
      break;
  }

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <img
          src="/dist/img/antares-dashboard-logo.png"
          alt="Admin Logo"
          className="logo-aside"
        />
        <span class="brand-text font-weight-light"></span>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="info">
              <a className="d-block">Welcome, {localStorage.getItem("name")}</a>
            </div>
          </div>
          <div className="form-inline"></div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <Link to="/home">
                  <a className="nav-link">
                    <i className="nav-icon fas fa-home" />
                    <p>Home</p>
                  </a>
                </Link>
              </li>
              <li className="nav-item" hidden={isHidden}>
                <Link to="/form">
                  <a className="nav-link">
                    <i className="nav-icon fas fa-edit" />
                    <p>Form</p>
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/table">
                  <a className="nav-link">
                    <i className="nav-icon fas fa-table" />
                    <p>Table</p>
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/report">
                  <a className="nav-link">
                    <i className="nav-icon fas fa-archive" />
                    <p>Report</p>
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link>
                  <a className="nav-link" onClick={logout}>
                    <i className="nav-icon fas fa-reply-all" />
                    <p>Log Out</p>
                  </a>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}

export default Aside;
