import React, { useEffect, useState } from "react";
import Aside from "./Aside";
import Header from "./Header";
import ButtonStatus from "./Home/ButtonStatus";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useHistory } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import CompChart from "./CompChart";
import ProbChart from "./ProbChart";

function Content() {
  const history = useHistory();

  function onLogout() {
    localStorage.clear();
    history.push("/login");
  }

  return (
    <>
      <Aside logout={onLogout} />
      <Header />{" "}
      <section class="content">
        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0">Home</h1>
                  </div>
                </div>
              </div>
            </div>
            <ButtonStatus />

            <div className="card-body">
              <div className="tab-content p-0">
                {/* Morris chart - Sales */}
                <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title">Chart</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body p-0">
                    <div className="table-responsive"></div>
                  </div>
                  <Tabs>
                    <TabList>
                      <Tab>Top Complaint</Tab>
                      <Tab>Top Error</Tab>
                    </TabList>
                    <TabPanel>
                      <CompChart />
                    </TabPanel>
                    <TabPanel>
                      <ProbChart />
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Content;