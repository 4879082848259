import React, { useEffect, useState } from "react";
import Aside from "./Aside";
import Header from "./Header";
import { appConfig } from "../config/App";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { getRoleAndUserId } from "../utils/getRoleAndUserId";
import Swal from "sweetalert2";

const FormulirDelivery = () => {
  const { noTicket } = useParams();
  const history = useHistory();

  function onLogout() {
    localStorage.clear();
    history.push("/login");
  }

  const [data, setData] = useState([]);
  const [delivery, setDelivery] = useState({});
  const [cs, setCs] = useState({});

  useEffect(() => {
    setDelivery(getTicketData());
  }, []);

  const handleCancel = () => {
    history.push("/table");
  };

  async function getTicketData() { 
     try {
     const authToken = localStorage.getItem("accessToken");
      const res = await axios.get(
        `${appConfig.baseApiUrl}/ticket?ticket=${noTicket}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setDelivery(res.data.data);
      setCs(res.data.data);
      return res.data.data;
    } catch (error) {}
  }

  const handleSubmit = async (sendData) => {
    sendData.userId = getRoleAndUserId().userId;
const authToken = localStorage.getItem("accessToken");
    const req = {
      delivery: sendData,
      ticket: {
        ...cs,
        ...sendData,
      },
    };
    if (sendData.ticket) {
      req.ticket = {serialNumber: sendData.ticket.serialNumber}
    }
    try {
      const res = await axios.put(
        `${appConfig.baseApiUrl}/ticket?noTicket=${noTicket}`,
        req,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Tiketmu Telah Diupdate!",
      });
      history.push("/table");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: "Terjadi kesalahan saat mengupdate tiket, silakan isi dengan benar.",
      });
    }
  };

  return (
    <>
      <Header />
      <Aside logout={onLogout} />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Hi, {localStorage.getItem("name")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Update Ticket</li>
                  <li className="breadcrumb-item active">Delivery</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="row flex justify-content-center">
            <div className="col-md-10">
              <div className="card card-red">
                <div className="card-header">
                  <h3 className="card-title">Delivery</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                <div className="form-group">
                    <label htmlFor="inputName"> Serial Number</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={cs.serialNumber}
                      onChange={(e) =>
                        setData({ ...data, ticket: {serialNumber: e.target.value} })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputEstimatedBudget">Device In</label>
                    <input
                      type="date"
                      id="inputName"
                      className="form-control"
                      defaultValue={delivery?.delivery?.deviceIn}
                      onChange={(e) =>
                        setData({ ...data, deviceIn: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputEstimatedBudget">Device Out</label>
                    <input
                      type="date"
                      id="inputName"
                      className="form-control"
                      defaultValue={delivery?.delivery?.deviceOut}
                      onChange={(e) =>
                        setData({ ...data, deviceOut: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputEstimatedBudget">Expedisi</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={delivery?.delivery?.expedisi}
                      onChange={(e) =>
                        setData({ ...data, expedisi: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputSpentBudget">No Resi</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={delivery?.delivery?.resi}
                      onChange={(e) =>
                        setData({ ...data, resi: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputEstimatedBudget">Remarks</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={delivery?.delivery?.remark}
                      onChange={(e) =>
                        setData({ ...data, remark: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <input
                      onClick={() => handleSubmit(data)}
                      type="submit"
                      defaultValue="Create new Project"
                      className="btn btn-success float-right mr-3"
                    />
                    <a
                      className="btn btn-danger float-left ml-3"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
    </>
  );
};

export default FormulirDelivery;
