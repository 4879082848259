import React from "react";
import { Link } from "react-router-dom";
import TiketInput from "../../component/Landingpages/InputTicket";
import CreateTicket from "../../component/Landingpages/CreateTicket";
import FiturWhatsapp from "../../component/Landingpages/FiturWhatsapp";

class LandingPage extends React.Component {
  render() {
    return (
      <section className="landingpage">
        <Link to="/login" className="btn-login btn float-right mr-2">
          Masuk
        </Link>
        <div className="container landingpage__container">
          <section className="mt-1 pt-1">
            <div className="landingpage__body-text">
              <h2 className="text-white mt-5 pt-5"></h2>
              <div className="mt-4 pt-4"></div>
              <TiketInput />
              <CreateTicket />
            </div>
          </section>
        </div>
        <FiturWhatsapp />
      </section>
    );
  }
}

export default LandingPage;
