import React from "react";

class LoginInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
    };

    this.onUsernameChangeHandler = this.onUsernameChangeHandler.bind(this);
    this.onPasswordChangeHandler = this.onPasswordChangeHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  onUsernameChangeHandler(event) {
    this.setState(() => {
      return {
        username: event.target.value,
      };
    });
  }

  onPasswordChangeHandler(event) {
    this.setState(() => {
      return {
        password: event.target.value,
      };
    });
  }

  onSubmitHandler(event) {
    event.preventDefault();

    this.props.login({
      username: this.state.username,
      password: this.state.password,
    });
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center container mb-3 pb-3">
        <form onSubmit={this.onSubmitHandler} className="text-white mx-3 px-3">
          <div class="form-group">
            <label>Username</label>
            <input
              type="text"
              placeholder="Username"
              className="form-control form-control-lg"
              value={this.state.username}
              onChange={this.onUsernameChangeHandler}
            />
          </div>
          <div class="form-group">
            <label>Password</label>
            <input
              type="password"
              placeholder="Password"
              className="form-control form-control-lg"
              value={this.state.password}
              onChange={this.onPasswordChangeHandler}
            />
          </div>
          <div className="div-button">
            <button className="button-cek">Masuk</button>
          </div>
        </form>
      </div>
    );
  }
}

export default LoginInput;
