import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter } from "react-router-dom";
// import App from "./App";
import ListPages from "./routes";

import "../src/styles/style.css";
import "../src/styles/landingpage.css";
// import '../src/styles/home.css';

const root = createRoot(document.getElementById("root"));

root.render(
  <HashRouter>
    <ListPages />
  </HashRouter>
);