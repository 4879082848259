import React, { useState } from "react";
import Aside from "./Aside";
import Header from "./Header";
import { appConfig } from "../config/App";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { getRoleAndUserId } from "../utils/getRoleAndUserId";
import Swal from "sweetalert2";

const Formulir = () => {
  const history = useHistory();
  function onLogout() {
    localStorage.clear();
    history.push("/login");
  }

  const [data, setData] = useState([]);
  
  const handleSubmit = async (sendData) => {
    sendData.userId = getRoleAndUserId().userId;
    try {
      const authToken = localStorage.getItem("accessToken");
      const res = await axios.post(appConfig.apiUrl, sendData, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Tiketmu Telah Dibuat!",
      });
      history.push("/table");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: "Terjadi kesalahan saat membuat tiket, silakan isi dengan benar.",
      });
    }
  };

  const handleCancel = () => {
    history.push("/home");
  };

  return (
    <>
      <Header />
      <Aside logout={onLogout} />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Hi, {localStorage.getItem("name")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item active">Create Ticket</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="d-flex justify-content-center">
            <div className="col-md-8">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Customer Service</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputName">Customer</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      list="customer"
                      onChange={(e) =>
                        setData({ ...data, customer: e.target.value })
                      }
                    />
                    <datalist id="customer">
                      <option value="">datalist an option</option>
                      <option value="PT. ADIRA DINAMIKA MULTIFINANCE TBK">
                        PT. ADIRA DINAMIKA MULTIFINANCE TBK
                      </option>
                      <option value="PT. SHIP MANAGEMENT SYSTEM CONSULTINDO">
                        PT. SHIP MANAGEMENT SYSTEM CONSULTINDO
                      </option>
                      <option value="PT. EXI GLOBAL APLIKASI">
                        PT. EXI GLOBAL APLIKASI
                      </option>
                      <option value="PT. REYCOM INTEGRATED SOLUSI">
                        PT. REYCOM INTEGRATED SOLUSI
                      </option>
                      <option value="PT. LITA SATU TEKNOLOGI (LITA TECH)">
                        PT. LITA SATU TEKNOLOGI (LITA TECH)
                      </option>
                      <option value="PT. NUTECH INTEGRASI">
                        PT. NUTECH INTEGRASI
                      </option>
                      <option value="PERHIMPUNAN BANK PERKREDITAN RAKYAT INDONESIA">
                        PERHIMPUNAN BANK PERKREDITAN RAKYAT INDONESIA
                      </option>
                      <option value="PERBARINDO">PERBARINDO</option>
                      <option value="PT. ROBICODE KREASI INDONESIA">
                        PT. ROBICODE KREASI INDONESIA
                      </option>
                      <option value="CV. SAMUDAYA KREASI VISITAMA">
                        CV. SAMUDAYA KREASI VISITAMA
                      </option>
                      <option value="PT. BPR BANK GUNA DAYA">
                        PT. BPR BANK GUNA DAYA
                      </option>
                      <option value="PT. BPR BINALANGGENG MULIA">
                        PT. BPR BINALANGGENG MULIA
                      </option>
                      <option value="PT. BPR UNIVERSAL KALBAR">
                        PT. BPR UNIVERSAL KALBAR
                      </option>
                      <option value="PT. BPR BUSANTARA BONA PASOGIT 11">
                        PT. BPR BUSANTARA BONA PASOGIT 11
                      </option>
                      <option value="DISDUKCAPIL PROVINSI PAPUA BARAT">
                        DISDUKCAPIL PROVINSI PAPUA BARAT
                      </option>
                      <option value="PT. NUSANTARA GLOBAL INOVASI">
                        PT. NUSANTARA GLOBAL INOVASI
                      </option>
                      <option value="PT. BPR CHANDRA MULTIARTHA">
                        PT. BPR CHANDRA MULTIARTHA
                      </option>
                      <option value="PT. ROBICOM CIPTA SOLUSINDO">
                        PT. ROBICOM CIPTA SOLUSINDO
                      </option>
                      <option value="PT. BPR DHANA MITRATAMA">
                        PT. BPR DHANA MITRATAMA
                      </option>
                      <option value="PT. BPR CITRA DANA MANDIRI">
                        PT. BPR CITRA DANA MANDIRI
                      </option>
                      <option value="PT BANK NEGARA INDONESIA ( persero ) Tbk">
                        PT BANK NEGARA INDONESIA ( persero ) Tbk
                      </option>
                      <option value="PT BANK MESTIKA DHARMA Tbk">
                        PT BANK MESTIKA DHARMA Tbk
                      </option>
                      <option value="PT BANK MEGA">PT BANK MEGA</option>
                      <option value="PT BANK ACEH">PT BANK ACEH</option>
                      <option value="PT. KALINGGA MITRATECH KREASI">
                        PT. KALINGGA MITRATECH KREASI
                      </option>
                      <option value="PT FINFLEET TEKNOLOGI INDONESIA">
                        PT FINFLEET TEKNOLOGI INDONESIA
                      </option>
                      <option value="PT BPR">PT BPR</option>
                    </datalist>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">Detail Customer</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, detailCustomer: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputStatus">Product</label>
                    <select
                      id="inputStatus"
                      className="form-control custom-select"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setData({ ...data, type: e.target.value });
                      }}
                    >
                      <option>Select one</option>
                      <option>ABAKA D</option>
                      <option>ABAKA D+</option>
                      <option>ABAKA DR</option>
                      <option>ABAKA H</option>
                      <option>ABAKA H+</option>
                      <option>ABAKA D ORANGE</option>
                      <option>CHARGER H02</option>
                      <option>ABAKA H02</option>
                      <option>ABAKA D-LITE</option>
                      <option>KIOSK</option>
                      <option>ALPINE AL-ONE</option>
                      <option>Z90</option>
                      <option>BATTERY H02</option>
                      <option>BATTERY D+</option>
                      <option>CHARGER D+</option>
                      
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName"> Serial Number</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, serialNumber: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName"> No. SAM/Perso</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, samperso: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputStatus">Status</label>
                    <select
                      id="inputStatus"
                      className="form-control custom-select"
                      onChange={(e) =>
                        setData({ ...data, status: e.target.value })
                      }
                    >
                      <option>Select one</option>
                      <option>open</option>
                      <option>pending</option>
                      <option>success</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">Status Pembayaran</label>
                    <select
                      id="inputStatus"
                      className="form-control custom-select"
                      onChange={(e) =>
                        setData({ ...data, payStatus: e.target.value })
                      }
                    >
                      <option>Select one</option>
                      <option value={true}>Sudah</option>
                      <option value={false}>Belum</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">Tanggal Pembayaran</label>
                    <input
                      type="date"
                      id="inputName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, payDate: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">Line</label>
                    <select
                      id="inputStatus"
                      className="form-control custom-select"
                      onChange={(e) =>
                        setData({ ...data, line: e.target.value })
                      }
                    >
                      <option>Select one</option>
                      <option>WA</option>
                      <option>Email</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputDescription">Case</label>
                    <input
                      id="inputDescription"
                      className="form-control"
                      defaultValue={""}
                      onChange={(e) =>
                        setData({ ...data, case: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">Remarks</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, remark: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">Resi Accepted</label>
                    <input
                      type="date"
                      id="inputName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, resiAccepted: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">Expedisi</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, expedisi: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">No Resi</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, noResi: e.target.value })
                      }
                    />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <a
                        onClick={() => handleCancel()}
                        className="btn btn-danger"
                      >
                        Cancel
                      </a>
                      <input
                        onClick={() => handleSubmit(data)}
                        type="submit"
                        defaultValue="Create new Project"
                        className="btn btn-success float-right"
                      />
                    </div>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
    </>
  );
};

export default Formulir;
