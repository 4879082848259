import React, { useEffect, useState } from "react";
import Aside from "./Aside";
import Header from "./Header";
import { appConfig } from "../config/App";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { getRoleAndUserId } from "../utils/getRoleAndUserId";
import Swal from "sweetalert2";

const FormulirService = () => {
  const { noTicket } = useParams();
  const history = useHistory();

  function onLogout() {
    localStorage.clear();
    history.push("/login");
  }

  const [data, setData] = useState([]);
  const [service, setService] = useState({});
  const [cs, setCs] = useState({});

  useEffect(() => {
    setService(getTicketData());
  }, []);

  async function getTicketData() {
    try {
      const authToken = localStorage.getItem("accessToken");
      const res = await axios.get(
        `${appConfig.baseApiUrl}/ticket?ticket=${noTicket}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setService(res.data.data);
      setCs(res.data.data);
      return res.data.data;
    } catch (error) { }
  }

  const handleSubmit = async (sendData) => {
    sendData.userId = getRoleAndUserId().userId;
    const req = {
      service: sendData,
      ticket: {
        ...cs,
        ...sendData,
      },
    };
    if (sendData.ticket) {
      req.ticket = { status: sendData.ticket.status }
      req.ticket = {serialNumber: sendData.ticket.serialNumber}
    }
    try {
      const authToken = localStorage.getItem("accessToken");
      const res = await axios.put(
        `${appConfig.baseApiUrl}/ticket?noTicket=${noTicket}`,
        req,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Tiketmu Telah Diupdate!",
      });
      history.push("/table");
    } catch (error) { 
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: "Terjadi kesalahan saat mengupdate tiket, silakan isi dengan benar.",
      });
    }
  };

  const handleCancel = () => {
    history.push("/table");
  };

  return (
    <>
      <Header />
      <Aside logout={onLogout} />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Hi, {localStorage.getItem("name")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Update Ticket</li>
                  <li className="breadcrumb-item active">Service</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="row flex justify-content-center">
            <div className="col-md-10">
              <div className="card card-green">
                <div className="card-header">
                  <h3 className="card-title">Service</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                <div className="form-group">
                    <label htmlFor="inputName"> Serial Number</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={cs.serialNumber}
                      onChange={(e) =>
                        setData({ ...data, ticket: {serialNumber: e.target.value} })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputEstimatedBudget">
                      Hasil Cek Kendala
                    </label>
                    <input
                      type="text"
                      id="inputHasilCek"
                      list="case"
                      className="form-control"
                      defaultValue={service?.service?.hasilCek}
                      onChange={(e) =>
                        setData({ ...data, hasilCek: e.target.value })
                      }
                    />
                    <datalist id="case">
                      <option value="">datalist an option</option>
                      <option value="Fingerprint">Fingerprint</option>
                      <option value="Software">Software</option>
                      <option value="Baterai">Baterai</option>
                      <option value="Port USB">Port USB</option>
                      <option value="Layar LCD">Layar LCD</option>
                      <option value="Hardware">Hardware</option>
                      <option value="No Trouble">No Trouble</option>
                      <option value="RFID">RFID</option>
                      <option value="UID">UID</option>
                      <option value="Slot SAM">Slot SAM</option>
                    </datalist>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputSpentBudget">Service Selesai</label>
                    <input
                      type="date"
                      id="inputName"
                      className="form-control"
                      defaultValue={service?.service?.serviceDone}
                      onChange={(e) =>
                        setData({ ...data, serviceDone: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputEstimatedBudget">Activity</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={service?.service?.activity}
                      onChange={(e) =>
                        setData({ ...data, activity: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputEstimatedBudget">No PO Service</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={service?.service?.noPo}
                      onChange={(e) =>
                        setData({ ...data, noPo: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputStatus">Last Update</label>
                    <select
                      id="inputStatus"
                      className="form-control custom-select"
                      onChange={(e) =>
                        setData({ ...data, ticket: { status: e.target.value } })
                      }
                    >
                      <option selected disabled>
                        Select one
                      </option>
                      <option
                        selected={service?.status === "on-going"}
                        value={"on-going"}
                      >
                        on-going
                      </option>
                      <option
                        selected={service?.status === "success"}
                        value={"success"}
                      >
                        Success
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputEstimatedBudget">New SN</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={service?.service?.newSn}
                      onChange={(e) =>
                        setData({ ...data, newSn: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputEstimatedBudget">New Sam</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={service?.service?.newSam}
                      onChange={(e) =>
                        setData({ ...data, newSam: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <input
                      onClick={() => handleSubmit(data)}
                      type="submit"
                      defaultValue="Create new Project"
                      className="btn btn-success float-right mr-3"
                    />
                    <a
                      className="btn btn-danger float-left ml-3"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
    </>
  );
};

export default FormulirService;
