import React, { useEffect, useState } from "react";
import Aside from "./Aside";
import Header from "./Header";
import { appConfig } from "../config/App";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { getRoleAndUserId } from "../utils/getRoleAndUserId";
import Swal from "sweetalert2";

const FormulirCs = () => {
  const { noTicket } = useParams();
  const history = useHistory();

  function onLogout() {
    localStorage.clear();
    history.push("/login");
  }

  const [data, setData] = useState([]);
  const [cs, setCs] = useState({});

  useEffect(() => {
    setCs(getTicketData());
  }, []);

  async function getTicketData() {
   
    try {
       const authToken = localStorage.getItem("accessToken");
       const res = await axios.get(
        `${appConfig.baseApiUrl}/ticket?ticket=${noTicket}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setCs(res.data.data);
      return res.data.data;
    } catch (error) {}
  }

  const handleSubmit = async (sendData) => {
    sendData.userId = getRoleAndUserId().userId;
    const req = {
      ticket: {
        ...cs,
        ...sendData,
      },
    };
    try {
      const authToken = localStorage.getItem("accessToken");
      const res = await axios.put(
        `${appConfig.baseApiUrl}/ticket?noTicket=${noTicket}`,
        req,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Tiketmu Telah Diupdate!",
      });
      history.push("/table");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: "Terjadi kesalahan saat mengupdate tiket, silakan isi dengan benar.",
      });
    }
  };

  const handleCancel = () => {
    history.push("/table");
  };

  return (
    <>
      <Header />
      <Aside logout={onLogout} />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Hi, {localStorage.getItem("name")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Update Ticket</li>
                  <li className="breadcrumb-item active">Customer Service</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="row flex justify-content-center">
            <div className="col-md-10">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Costumer Service</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputName">Customer</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={cs.customer}
                      onChange={(e) =>
                        setData({ ...data, customer: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">Detail Costumer</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={cs.detailCustomer}
                      onChange={(e) =>
                        setData({ ...data, detailCustomer: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputStatus">Product</label>
                    <select
                      id="inputStatus"
                      className="form-control custom-select"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setData({ ...data, type: e.target.value });
                      }}
                    >
                      <option>Select one</option>
                      <option selected={cs.type === "ABAKA D"}>ABAKA D</option>
                      <option selected={cs.type === "ABAKA D+"}>
                        ABAKA D+
                      </option>
                      <option selected={cs.type === "ABAKA DR"}>
                        ABAKA DR
                      </option>
                      <option selected={cs.type === "ABAKA H"}>ABAKA H</option>
                      <option selected={cs.type === "ABAKA H+"}>ABAKA H+</option>
                      <option selected={cs.type === "CHARGER H02"}>
                        CHARGER H02
                      </option>
                      <option selected={cs.type === "ABAKA H02"}>
                        ABAKA H02
                      </option>
                      <option selected={cs.type === "KIOSK"}>
                        KIOSK
                      </option>
                      <option selected={cs.type === "ABAKA D-LITE"}>
                        ABAKA D-LITE
                      </option>
                      <option selected={cs.type === "ABAKA D ORANGE"}>
                      ABAKA D ORANGE
                      </option>
                      <option selected={cs.type === "ALPINE AL-ONE"}>
                        ALPINE AL-ONE
                      </option>
                      <option selected={cs.type === "Kabel USB D"}>
                        Kabel USB D
                      </option>
                      <option selected={cs.type === "Z90"}>Z90</option>
                      <option selected={cs.type === "BATTERY H02"}>BATTERY H02</option>
                      <option selected={cs.type === "BATTERY D+"}>BATTERY D+</option>
                      <option selected={cs.type === "CHARGER D+"}>CHARGER D+</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName"> Serial Number</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={cs.serialNumber}
                      onChange={(e) =>
                        setData({ ...data, serialNumber: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName"> No. SAM/Perso</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={cs.samperso}
                      onChange={(e) =>
                        setData({ ...data, samperso: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputStatus">Status</label>
                    <select
                      id="inputStatus"
                      className="form-control custom-select"
                      defaultValue={cs.status}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setData({ ...data, status: e.target.value });
                      }}
                    >
                      <option>Select one</option>
                      <option selected={cs.status === "open"}>open</option>
                      <option selected={cs.status === "pending"}>
                        pending
                      </option>
                      <option selected={cs.status === "success"}
                        value={"success"}>success</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">Status Pembayaran</label>
                    <select
                      id="inputStatus"
                      className="form-control custom-select"
                      defaultValue={cs.payStatus}
                      onChange={(e) =>
                        setData({ ...data, payStatus: e.target.value })
                      }
                    >
                      <option>Select one</option>
                      <option selected={cs.payStatus === true} value={true}>
                        Sudah
                      </option>
                      <option selected={cs.payStatus === false} value={false}>
                        Belum
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">Tanggal Pembayaran</label>
                    <input
                      type="date"
                      id="inputName"
                      className="form-control"
                      defaultValue={cs.payDate}
                      onChange={(e) =>
                        setData({ ...data, payDate: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputDescription">Case</label>
                    <textarea
                      id="inputDescription"
                      className="form-control"
                      rows={2}
                      defaultValue={cs.case}
                      onChange={(e) =>
                        setData({ ...data, case: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">Remarks</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={cs.remark}
                      onChange={(e) =>
                        setData({ ...data, remark: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">Resi Accepted</label>
                    <input
                      type="date"
                      id="inputResiAccepted"
                      className="form-control"
                      defaultValue={cs.resiAccepted}
                      onChange={(e) =>
                        setData({ ...data, resiAccepted: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">Expedisi</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={cs.expedisi}
                      onChange={(e) =>
                        setData({ ...data, expedisi: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputName">No Resi</label>
                    <input
                      type="text"
                      id="inputName"
                      className="form-control"
                      defaultValue={cs.noResi}
                      onChange={(e) =>
                        setData({ ...data, noResi: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputLine">Line</label>
                    <select
                      id="inputLine"
                      className="form-control custom-select"
                      onChange={(e) =>
                        setData({ ...data, line: e.target.value })
                      }
                    >
                      <option>Select one</option>
                      <option selected={cs.line === "WA"}>WA</option>
                      <option selected={cs.line === "Email"}>Email</option>
                    </select>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <input
                        onClick={() => handleSubmit(data)}
                        type="submit"
                        defaultValue="Create new Project"
                        className="btn btn-success float-right ml-3"
                      />
                      <a
                        className="btn btn-danger float-left"
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
    </>
  );
};

export default FormulirCs;
