import React from "react";
import { withRouter } from "react-router-dom";

import BodyContent from "./BodyContent";

import { getTicketsByStatus } from "../../utils/api";

class ContentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataTicket: [],
    };
  }

  async componentDidMount() {
    const status = this.props.match.params.status;
    const { data } = await getTicketsByStatus(status);
    this.setState(() => {
      return {
        dataTicket: data.rows,
      };
    });
  }

  render() {
    console.log(this.state.dataTicket);

    return <BodyContent data={this.state.dataTicket} />;
  }
}

export default withRouter(ContentList);
