import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { getCountCase } from "../utils/api";

ChartJS.register(ArcElement, Tooltip, Legend);

class ProbChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      case: "",
      count: "",
    };
  }

  async componentDidMount() {
    const { data } = await getCountCase();

    let count = [];
    let cases = [];

    data.map((val) => {
      count.push(val.count);
      cases.push(val.case);
    });
    this.setState(() => {
      return {
        count: count,
        case: cases,
      };
    });
  }

  options = {
    maintainAspectRatio: true,
  };

  render() {
    return (
      <div className="container-sm col col-sm-6">
        <div>
          <Doughnut
            data={{
              labels: this.state.case,
              datasets: [
                {
                  label: "Total Complain",
                  data: this.state.count,
                  backgroundColor: [
                    "rgba(78, 25, 203)",
                    "rgba(224, 78, 28)",
                    "rgba(30, 219, 146)",
                  ],
                  borderColor: [
                    "rgba(78, 25, 203)",
                    "rgba(224, 78, 28)",
                    "rgba(30, 219, 146)",
                  ],
                  borderWidth: 2,
                },
              ],
            }}
          />
        </div>
      </div>
    );
  }
}

export default ProbChart;