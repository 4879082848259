import React from "react";

const TicketItem = (props) => {
  return (
    <>
      <div class="mt-5 pt-5"></div>
      <section>
        <div class="mt-2 pt-5"></div>
        <div class="container jumbotron mb-5 pt-3">
          <h3>Status Tiketmu</h3>
          <ul class="list-group list-group-flush mt-3 pt-3">
            <li class="list-group-item">
              <b>No Tiket: {props.data.noTicket}</b>
            </li>
            <li class="list-group-item">
              Tanggal Tiket: {props.data.createdAt}
            </li>
            <li className="list-group-item">
              Masa Garansi:
              {props.data.guaranteeDate
                ? props.data.isGuarantee
                  ? " Masih Garansi"
                  : " Sudah Exp"
                : ""}
            </li>
            <li class="list-group-item">Customer: {props.data.customer}</li>
            <li class="list-group-item">
              <b>Status: {props.data.status}</b>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default TicketItem;
