import React from "react";
import { useHistory } from "react-router-dom";

import LoginInput from "../../component/Login/LoginInput";

import { login } from "../../utils/api";

function LoginAdminPage() {
  const history = useHistory();

  async function onLogin({ username, password }) {
    const { error, data } = await login({ username, password });

    if (!error) {
      localStorage.setItem("accessToken", data.token);
      localStorage.setItem("name", data.data.personalInfo.name);
      history.push("/home");
    }
  }

  return (
    <>
      <div class="mt-5 pt-5"></div>
      <section className="mt-5">
        <span className="text-white d-flex justify-content-center ml-3 mt-5 pt-5"></span>
        <LoginInput login={onLogin} />
      </section>
    </>
  );
}

export default LoginAdminPage;