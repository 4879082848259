const BASE_URL = "https://antaresapi.idpay.co.id";

function getAccessToken() {
  return localStorage.getItem("accessToken");
}

function putAccessToken(accessToken) {
  return localStorage.setItem("accessToken", accessToken);
}

async function fetchWithToken(url, options = {}) {
  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
}

async function login({ username, password }) {
  const response = await fetch(`${BASE_URL}/auth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userName: username, userPassword: password }),
  });

  const responseJson = await response.json();

  if (responseJson.status !== 200) {
    alert(responseJson.message);
    return { error: true, data: null };
  }

  return { error: false, data: responseJson };
}

async function getUserLogged() {
  const response = await fetchWithToken(`${BASE_URL}/auth`);
  const responseJson = await response.json();

  if (responseJson.status !== 200) {
    return { error: true, data: null };
  }

  return { error: false, data: responseJson.data };
}

async function getTicketNoToken(noTicket) {
  const response = await fetch(`${BASE_URL}/ticket/cek?ticket=${noTicket}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const responseJson = await response.json();

  if (responseJson.status !== 200) {
    // alert(responseJson.message);
    return { error: true, data: [] };
  }

  return { error: false, data: responseJson.data };
}

async function getTicketsByStatus(status) {
  const response = await fetchWithToken(`${BASE_URL}/ticket?status=${status}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseJson = await response.json();

  if (responseJson.status !== 200) {
    alert(responseJson.message);
    return { error: true, data: [] };
  }

  return { error: false, data: responseJson.data };
}

async function getStatusSuccess(success) {
  const response = await fetchWithToken(
    `${BASE_URL}/ticket?status=${success}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const responseJson = await response.json();

  if (responseJson.status !== 200) {
    alert(responseJson.message);
    return { error: true, data: [] };
  }

  return { error: false, data: responseJson.data };
}

async function getStatusPending(pending) {
  const response = await fetchWithToken(
    `${BASE_URL}/ticket?status=${pending}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const responseJson = await response.json();

  if (responseJson.status !== 200) {
    alert(responseJson.message);
    return { error: true, data: [] };
  }

  return { error: false, data: responseJson.data };
}

async function getStatusOnGoing(ongoing) {
  const response = await fetchWithToken(
    `${BASE_URL}/ticket?status=${ongoing}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const responseJson = await response.json();

  if (responseJson.status !== 200) {
    alert(responseJson.message);
    return { error: true, data: [] };
  }

  return { error: false, data: responseJson.data };
}

async function getTicket(id) {
  const response = await fetchWithToken(`${BASE_URL}/ticket?noTicket=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseJson = await response.json();

  if (responseJson.status !== 200) {
    alert(responseJson.message);
    return { error: true, data: null };
  }

  return { error: false, data: responseJson.data };
}

async function getCountStatusTicket() {
  const response = await fetchWithToken(`${BASE_URL}/ticket/count`, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  });

  const responseJson = await response.json();

  if (responseJson.status !== 200) {
    alert(responseJson.message);
    return { error: true, data: null };
  }

  return { error: false, data: responseJson.data };
}

async function getCountCustomer() {
  const response = await fetchWithToken(
    `${BASE_URL}/ticket/count?by=customer`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    }
  );

  const responseJson = await response.json();

  if (responseJson.status !== 200) {
    alert(responseJson.message);
    return { error: true, data: null };
  }

  return { error: false, data: responseJson.data };
}

async function getCountCase() {
  const response = await fetchWithToken(`${BASE_URL}/ticket/count?by=case`, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  });

  const responseJson = await response.json();

  if (responseJson.status !== 200) {
    alert(responseJson.message);
    return { error: true, data: null };
  }

  return { error: false, data: responseJson.data };
}

export {
  getAccessToken,
  putAccessToken,
  login,
  getUserLogged,
  getTicketNoToken,
  getTicketsByStatus,
  getStatusPending,
  getStatusSuccess,
  getStatusOnGoing,
  getTicket,
  getCountStatusTicket,
  getCountCustomer,
  getCountCase,
  // getFetchTicket
};
