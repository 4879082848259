import React from "react";
import { withRouter } from "react-router-dom";

import {
  // getStatusOpen,
  // getStatusPending,
  // getStatusOnGoing,
  // getStatusSuccess,
  getCountStatusTicket,
} from "../../utils/api";

class ButtonStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: "",
      ongoing: "",
      pending: "",
      success: "",
    };

    this.onClickButtonOpenHandler = this.onClickButtonOpenHandler.bind(this);
    this.onClickButtonPendingHandler =
      this.onClickButtonPendingHandler.bind(this);
    this.onClickButtonOnGoingHandler =
      this.onClickButtonOnGoingHandler.bind(this);
    this.onClickButtonSuccessHandler =
      this.onClickButtonSuccessHandler.bind(this);
  }

  async componentDidMount() {
    const { data } = await getCountStatusTicket();

    this.setState(() => {
      return {
        open: data[0].open,
        pending: data[0].pending,
        success: data[0].success,
        ongoing: data[0].ongoing,
      };
    });
  }

  async onClickButtonOpenHandler(event) {
    event.preventDefault();
    this.props.history.push({
      pathname: `/content-list/open`,
    });
  }

  async onClickButtonPendingHandler(event) {
    event.preventDefault();
    this.props.history.push({
      pathname: `/content-list/pending`,
    });
  }

  async onClickButtonOnGoingHandler(event) {
    event.preventDefault();
    this.props.history.push({
      pathname: `/content-list/on-going`,
    });
  }

  async onClickButtonSuccessHandler(event) {
    event.preventDefault();
    this.props.history.push({
      pathname: `/content-list/success`,
    });
  }

  render() {
    return (
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6">
              <div
                className="small-box bg-info"
                onClick={this.onClickButtonOpenHandler}
              >
                <div className="inner">
                  <h3>{this.state.open}</h3>
                  <p>Tiket Terbuka</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="small-box bg-success"
                onClick={this.onClickButtonSuccessHandler}
              >
                <div className="inner">
                  <h3>{this.state.success}</h3>
                  <p>Sukses</p>
                </div>
                <div className="icon">
                  <i className="ion icons ion-ios-checkmark-outline" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="small-box bg-warning"
                onClick={this.onClickButtonOnGoingHandler}
              >
                <div className="inner">
                  <h3>{this.state.ongoing}</h3>
                  <p>Sedang Berlangsung </p>
                </div>
                <div className="icon">
                  <i className="ion icons ion-android-alert" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div
                className="small-box bg-primary"
                onClick={this.onClickButtonPendingHandler}
              >
                <div className="inner">
                  <h3>{this.state.pending}</h3>
                  <p>Tertunda</p>
                </div>
                <div className="icon">
                  <i className="ion icons ion-android-folder-open" />
                </div>
              </div>
            </div>
          </div>
          <section className="content"></section>
        </div>
      </section>
    );
  }
}

export default withRouter(ButtonStatus);
