import React from "react";
import { useRef } from "react";
import Swal from "sweetalert2";

import emailjs from "emailjs-com";

const InputCreateTicket = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_byuvzct",
        "template_6cfo2qc",
        form.current,
        "WyZ61DOqwk7Yamkbk"
      )
      .then(
        (result) => {
          Swal.fire({
            title: "Permintaan Tiket Diterima!",
            text: "Harap tunggu, kami akan menghubungi Anda segera.",
            icon: "success",
            button: "Ok",
          });
        },
        (error) => {
          Swal.fire({
            title: "Error!",
            text: "Terjadi kesalahan, harap ulangi kembali prosesnya.",
            icon: "error",
            button: "Ok",
          });
        }
      );

    e.target.reset();
  };

  return (
    <div class="justify-content-center input-create-ticket">
      <>
        <div class="jumbotron justify-content-center">
          <div class="">
            <form ref={form} onSubmit={sendEmail}>
              <div class="form-group col col-sm-12">
                <label>Nama</label>
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  name="name"
                  placeholder="Nama lengkap"
                  required
                />
              </div>
              <div class="form-group col col-sm-12">
                <label>Email</label>
                <input
                  id="email"
                  type="email"
                  class="form-control"
                  name="email"
                  placeholder="Email"
                  required
                />
              </div>
              <div class="form-group col col-sm-12">
                <label>No Handphone</label>
                <input
                  id="number"
                  type="number"
                  class="form-control"
                  name="number"
                  placeholder="Nomor Telpon atau WhatsApp"
                  required
                />
              </div>
              <div class="form-group col col-sm-12">
                <label>Masalah</label>
                <textarea
                  id="message"
                  class="form-control"
                  name="message"
                  rows="1"
                  placeholder="Pesan atau keluhan yang dihadapi"
                  required
                ></textarea>
              </div>
              <div class="form-group col col-sm-12">
                <label>Perusahaan</label>
                <input
                  id="company"
                  type="text"
                  class="form-control"
                  name="company"
                  placeholder="Nama Perusahaan"
                  required
                />
              </div>
              <button type="submit" className="ml-2 btn btn-info">
                Buat Ticket
              </button>
            </form>
          </div>
        </div>
      </>
    </div>
  );

  //   return (
  //     <div class="justify-content-center">
  //       <div>
  //         <div class="jumbotron mb-5 my-5 py-5 justify-content-center">
  //           <form
  //             action="https://formsubmit.co/5e9c3c569a6efebf9eff38b247e57f52"
  //             method="POST"
  //           >
  //             <div class="form-group col  col-sm-12">
  //               <label>Nama</label>
  //               <input
  //                 id="name"
  //                 type="text"
  //                 class="form-control"
  //                 name="name"
  //                 placeholder="Nama lengkap"
  //                 required
  //               />
  //             </div>
  //             <div class="form-group col col-sm-12">
  //               <label>Email</label>
  //               <input
  //                 id="email"
  //                 type="email"
  //                 class="form-control"
  //                 name="email"
  //                 placeholder="Email"
  //                 required
  //               />
  //             </div>
  //             <div class="form-group col col-sm-12">
  //               <label>No Handphone</label>
  //               <input
  //                 id="number"
  //                 type="number"
  //                 class="form-control"
  //                 name="number"
  //                 placeholder="Nomor Telpon atau WhatsApp"
  //                 required
  //               />
  //             </div>
  //             <div class="form-group col col-sm-12">
  //               <label>Masalah</label>
  //               <textarea
  //                 id="message"
  //                 class="form-control"
  //                 name="message"
  //                 rows="1"
  //                 placeholder="Pesan atau keluhan yang dihadapi"
  //                 required
  //               ></textarea>
  //             </div>
  //             <div class="form-group col col-sm-12">
  //               <label>Perusahaan</label>
  //               <input
  //                 id="company"
  //                 type="text"
  //                 class="form-control"
  //                 name="company"
  //                 placeholder="Nama Perusahaan"
  //                 required
  //               />
  //             </div>
  //             <button type="submit" className="ml-2 btn btn-info">
  //               Buat Ticket
  //             </button>
  //           </form>
  //         </div>
  //         ;
  //       </div>
  //     </div>
  //   );
};

export default InputCreateTicket;
