import React from "react";
import { Link } from "react-router-dom";

function CreateTicket() {
  return (
    <div className="landingpage__body-no-ticket">
      <h5 class="text-white">
        Belum memiliki tiket?{" "}
        <Link to="/create-ticket-visitor">
          <button class="button btn btn-info box-button button-create-ticket">
            <span>Buat Tiket</span>
          </button>
        </Link>
      </h5>
    </div>
  );
}

export default CreateTicket;
