import React from "react";

const TicketDetail = (props) => {
  let data = props.data.rows[0];
  return (
    <section className="container  justify-content-center">
      <div className="mt-5 pt-5">
        <div class="container jumbotron mb-3 pt-3">
          <h4>Tiket Detail</h4>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">No Tiket: {data.noTicket}</li>
            <li className="list-group-item">Tanggal Tiket: {data.createdAt}</li>
            <li className="list-group-item col  col-sm-12">
              Status: {data.status}
            </li>
            <li className="list-group-item col  col-sm-12">
              Customer: {data.customer}
            </li>
            <li className="list-group-item col  col-sm-12">
              Customer Detail: {data.detailCustomer}
            </li>
            <li className="list-group-item col  col-sm-12">
              Tipe: {data.type}
            </li>
            <li className="list-group-item col  col-sm-12">
              Masalah: {data.case}
            </li>
            <li className="list-group-item col  col-sm-12">
              Catatan: {data.remark}
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default TicketDetail;
