import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { getCountCustomer } from "../utils/api";

ChartJS.register(ArcElement, Tooltip, Legend);

class Donat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      total: "",
      customer: "",
      count: "",
    };
  }

  async componentDidMount() {
    const { data } = await getCountCustomer();

    let total = [];
    let customer = [];
    let count = [];

    data.map((val) => {
      total.push(val.total);
      customer.push(
        `${val.customer} : Open ${val.count.open}, Pending ${val.count.pending}, Ongoing ${val.count.ongoing}, Success ${val.count.success}`
      );
      count.push(val.count);
    });

    this.setState(() => {
      return {
        total: total,
        customer: customer,
        count: count,
      };
    });
  }

  options = {
    maintainAspectRatio: true,
  };

  render() {
    return (
      <div className="container-sm col col-sm-7 justify-content-center">
        <div>
          <Doughnut
            data={{
              labels: this.state.customer,
              datasets: [
                {
                  label: "Total Komplain",
                  data: this.state.total,

                  backgroundColor: [
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                    "rgba(52, 243, 21, 0.8)",
                  ],
                  borderColor: [
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                    "rgba(52, 243, 21, 0.8)",
                  ],
                  borderWidth: 2,
                },
              ],
            }}
          />
        </div>
      </div>
    );
  }
}

export default Donat;
